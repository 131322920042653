import styles from "./leftSubPanelCont3.module.css";
import CombinedChart from "./chart/combinedChart";

function LeftSubPanelCont3(props) {
  return (
    <div class={styles.leftSubPanelCont3}>
      <div class={styles.sub1}>
        <CombinedChart chartData={props.chartData} />
      </div>
    </div>
  );
}

export default LeftSubPanelCont3;
