import styles from "./rightSubPanelCont1.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";

function RightPanelSubCont1(props) {
  const [selectedDate, onChange] = useState(new Date());

  useEffect(() => {
    props.setDate(selectedDate);

    const handleClickOutsideCalendar = (event) => {
      const calendarContainer = document.querySelector(".react-calendar");

      if (calendarContainer && !calendarContainer.contains(event.target)) {
        return;
      }
    };

    window.addEventListener("click", handleClickOutsideCalendar);

    return () => {
      window.removeEventListener("click", handleClickOutsideCalendar);
    };
  }, [selectedDate, props]);

  return (
    <div class={styles.rightPanelSubCont1}>
      <div class={styles.sub1}>
        <Calendar
          onChange={onChange}
          // showWeekNumbers
          maxDate={new Date()}
          showFixedNumberOfWeeks
          value={selectedDate}
          // showNavigation={false}
        />
      </div>
    </div>
  );
}

export default RightPanelSubCont1;
