import React, { useLayoutEffect } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  Label,
  LabelList,
  Scroller,
} from "recharts";

import { useRef, useEffect, useState } from "react";
import styles from "../leftSubPanelCont3.module.css";

const CombinedChart = (props) => {
  const gradientColors1 = [
    "#de4569",
    "#bf406a",
    "#a73e70",
    "#8f3d77",
    "#613684",
  ];
  const gradientFill1 = `url(#gradient-fill1)`;

  const gradientColors2 = [
    "#41e8fe",
    "#2c4192",
    "#273687",
    "#23297d",
    "#211f61",
  ];
  const gradientFill2 = `url(#gradient-fill2)`;

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [labelAxis, setLabelAxis] = useState();
  const [chartContWidth, setChartContWidth] = useState();
  const [datasetKeys, setDatasetKeys] = useState([]);

  const handleResize = () => {
    setViewportHeight(window.innerHeight);
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (viewportWidth <= 1004) {
      setLabelAxis(2.4);
      return;
    }

    if (viewportHeight >= 1384 && viewportHeight <= 1445) {
      setLabelAxis(2.95);
    } else if (viewportHeight >= 1336 && viewportHeight <= 1383) {
      setLabelAxis(2.98);
    } else if (viewportHeight >= 1282 && viewportHeight <= 1335) {
      setLabelAxis(3.059);
    } else if (viewportHeight >= 1206 && viewportHeight <= 1281) {
      setLabelAxis(3.1);
    } else if (viewportHeight >= 1153 && viewportHeight <= 1205) {
      setLabelAxis(3.25);
    } else if (viewportHeight >= 1115 && viewportHeight <= 1152) {
      setLabelAxis(3.35);
    } else if (viewportHeight >= 1059 && viewportHeight <= 1114) {
      setLabelAxis(3.45);
    } else if (viewportHeight >= 1015 && viewportHeight <= 1058) {
      setLabelAxis(3.55);
    } else if (viewportHeight >= 960 && viewportHeight <= 1014) {
      setLabelAxis(3.7);
    } else if (viewportHeight >= 923 && viewportHeight <= 959) {
      setLabelAxis(3.75);
    } else if (viewportHeight >= 875 && viewportHeight <= 922) {
      setLabelAxis(3.95);
    } else if (viewportHeight >= 831 && viewportHeight <= 874) {
      setLabelAxis(4.195);
    } else if (viewportHeight >= 803 && viewportHeight <= 830) {
      setLabelAxis(4.45);
    } else if (viewportHeight >= 766 && viewportHeight <= 802) {
      setLabelAxis(4.6);
    } else if (viewportHeight >= 742 && viewportHeight <= 765) {
      setLabelAxis(4.8);
    } else if (viewportHeight >= 706 && viewportHeight <= 741) {
      setLabelAxis(5.2);
    } else if (viewportHeight >= 679 && viewportHeight <= 705) {
      setLabelAxis(5.7);
    } else if (viewportHeight >= 639 && viewportHeight <= 678) {
      setLabelAxis(6);
    } else if (viewportHeight >= 618 && viewportHeight <= 638) {
      setLabelAxis(6.8);
    } else if (viewportHeight >= 598 && viewportHeight <= 617) {
      setLabelAxis(7.3);
    } else if (viewportHeight <= 597) {
      setLabelAxis(8.7);
    }
  }, [viewportHeight, viewportWidth]);

  const CustomizedLabelTop = ({ x, y, value, height }) => (
    <text
      x={x}
      y={viewportHeight / labelAxis}
      dy={80}
      dx={20}
      textAnchor="middle"
      fill="#fff"
    >
      {value}
    </text>
  );

  const CustomizedLabelBottom = ({ x, y, value }) => (
    <text x={x} y={y} dy={-10} dx={8} textAnchor="middle" fill="#fff">
      {value}
    </text>
  );

  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const containerWidth = chartContainerRef.current.offsetWidth;
      const chartWidth = chartContainerRef.current.firstChild.offsetWidth;
      const centerScrollPosition = chartWidth - containerWidth;

      chartContainerRef.current.scrollLeft = centerScrollPosition;
    }
  }, [props.chartData]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const time = payload.find((entry) => entry.dataKey === "time");
      const tooltipContent = datasetKeys.map((key) => {
        const valueEntry = payload.find((entry) => entry.dataKey === key);
        return (
          <p key={key} className={styles.avgValue}>{`L${key}: ${
            valueEntry ? valueEntry.value : "-"
          }`}</p>
        );
      });

      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{`Time: ${
            payload?.[0]?.payload?.time ? payload[0].payload.time : "-"
          }`}</p>
          {tooltipContent}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (props.chartData.length <= 12) {
      setChartContWidth("100%");
    }
    if (props.chartData.length === 24) {
      setChartContWidth("205%");
    }
    if (props.chartData.length === 23) {
      setChartContWidth("200%");
    }
    if (props.chartData.length === 22) {
      setChartContWidth("190%");
    }
    if (props.chartData.length === 21) {
      setChartContWidth("180%");
    }
    if (props.chartData.length === 20) {
      setChartContWidth("170%");
    }
    if (props.chartData.length === 19) {
      setChartContWidth("162%");
    }
    if (props.chartData.length === 18) {
      setChartContWidth("152%");
    }
    if (props.chartData.length === 17) {
      setChartContWidth("146%");
    }
    if (props.chartData.length === 16) {
      setChartContWidth("136%");
    }
    if (props.chartData.length === 15) {
      setChartContWidth("130%");
    }
    if (props.chartData.length === 14) {
      setChartContWidth("123%");
    }
    if (props.chartData.length === 13) {
      setChartContWidth("115%");
    }
    const keysSet = new Set();

    props.chartData.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (key !== "time") {
          keysSet.add(key);
        }
      });
    });
    setDatasetKeys(Array.from(keysSet));
  }, [props.chartData]);

  useLayoutEffect(() => {
    const scrollToEnd = () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.scrollLeft =
          chartContainerRef.current.scrollWidth;
      }
    };

    // Use a timeout to ensure the chart is rendered before scrolling
    const timer = setTimeout(scrollToEnd, 100); // Adjust the delay as needed

    // Clean up timeout if component unmounts or chartData changes
    return () => clearTimeout(timer);
  }, [props.chartData]);

  return (
    <div
      ref={chartContainerRef}
      style={{
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        height: "100%",
      }}
    >
      <ResponsiveContainer width={chartContWidth} height="100%">
        <ComposedChart data={props.chartData}>
          <defs>
            <linearGradient id="gradient-fill1" x1="0" y1="0" x2="0" y2="1">
              {gradientColors1.map((color, index) => (
                <stop
                  key={index}
                  offset={`${index / (gradientColors1.length - 1)}`}
                  stopColor={color}
                />
              ))}
            </linearGradient>

            <linearGradient id="gradient-fill2" x1="0" y1="0" x2="0" y2="1">
              {gradientColors2.map((color, index) => (
                <stop
                  key={index}
                  offset={`${index / (gradientColors2.length - 1)}`}
                  stopColor={color}
                />
              ))}
            </linearGradient>
          </defs>
          <XAxis dataKey="time" tick={{ fill: "white" }} tickCount={140} />
          <YAxis tick={{ fill: "white" }} axis="left" />
          <CartesianGrid stroke="white" strokeWidth={0.5} />
          {datasetKeys &&
            datasetKeys.map((key, index) => {
              return (
                <>
                  <Bar
                    key={index}
                    isAnimationActive={false}
                    dataKey={key}
                    barSize={40}
                    stackId="stack"
                    fill={`url(#gradient-fill${index + 1})`}
                    barCategoryGap={20}
                    barGap={10}
                  >
                    <LabelList
                      dataKey={key}
                      content={<CustomizedLabelTop />}
                      position="top"
                    />
                  </Bar>
                  <Tooltip
                    cursor={{ stroke: "", strokeWidth: 2 }}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      borderRadius: "4px",
                    }}
                    content={<CustomTooltip />}
                    filterNull={false}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    dataKey={key}
                    stroke="white"
                    strokeWidth={0.8}
                    dot
                  ></Line>
                </>
              );
            })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CombinedChart;
