import { useEffect, useState } from "react";
import meinhardt from "../images/meinhardt-logo.png";
import styles from "./header.module.css";

function Header(props) {
  const [date, setDate] = useState(`-/-/-`);
  const [time, setTime] = useState("00:00");

  useEffect(() => {
    if (props.date.date) {
      const editedDate = `${props?.date?.date?.getDate()}-${props?.date?.date?.toLocaleString(
        "default",
        { month: "long" }
      )}-${props?.date?.date?.getFullYear()}`;

      const editedTime = `${props?.date?.time?.getHours()}:${props?.date?.time?.getMinutes()}`;

      setDate(editedDate);
      setTime(editedTime);
    }
  }, [props.date]);

  // console.log(props.date);

  return (
    <div className={styles.headerCont}>
      <img src={meinhardt} />
      <a href="https://app-portal-cn5.envisioniot.com/portal/abd255dd-bf53-4451-980c-a454b00121b7/fleet_single_building_overview">
        <img
          src="https://cdn-icons-png.flaticon.com/512/4300/4300059.png"
          height={40}
          width={40}
        />
      </a>
      <div className={styles.dateCont}>
        <p>Viewing data for : </p>
        <h6>{`\u00A0${date}`}</h6>
        <h6>{`\u00A0${time}`}</h6>
      </div>
    </div>
  );
}

export default Header;
