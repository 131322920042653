import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";
import { useState, useEffect } from "react";
import PercentCal, { RANGE } from "./PercentCal";

const SpiderChart = ({ resData, dayData, todayDate, date }) => {
  const [sOuterRadius, setsOuterRadius] = useState("90%");
  const [data, setData] = useState([]);
  function hresize() {
    if (window.innerWidth <= 860) {
      setsOuterRadius("140%");
    } else if (window.innerWidth > 1316) {
      setsOuterRadius("98%");
    }
  }

  window.addEventListener("resize", hresize);
  useEffect(() => {
    hresize();
  }, [sOuterRadius]);
  useEffect(() => {
    const dataForSpider =
      todayDate.getDate() === date.getDate() ? resData : dayData;

    const ch2o = PercentCal(dataForSpider?.ch2o, RANGE.CH2O);
    const co2 = PercentCal(dataForSpider?.co2, RANGE.CO2);
    const co = PercentCal(dataForSpider?.co, RANGE.CO);
    const ozone = dataForSpider?.ozone;
    const tvoc = PercentCal(dataForSpider?.tvoc, RANGE.TVOC);
    const pm25 = PercentCal(dataForSpider?.pm25, RANGE.PM);

    const copy = [
      {
        L9: 0.07,
        name: "Formaldehyde",
        color: "white",
        L12: ch2o,
        y: 10,
      },
      {
        L9: 7.4,
        name: "Carbon Dioxide ",
        color: "blue",
        L12: co2,
        y: 10,
      },
      {
        L9: 0.21,
        name: "Carbon Monoxide",
        color: "cyan",
        L12: co,
        y: 10,
      },
      {
        L9: 0.2,
        name: "Ozone",
        color: "purple",
        L12: ozone,
        y: 10,
      },
      {
        L9: 2.28,
        name: "TVOC",
        color: "brown",
        L12: tvoc,
        y: 10,
      },
      {
        L9: 0.84,
        name: "PM2.5",
        color: "black",
        L12: pm25,
        y: 10,
      },
    ];
    setData(copy);
  }, [resData]);
  // console.log(data);

  return (
    <RadarChart height={260} width={340} outerRadius={sOuterRadius} data={data}>
      <PolarGrid />
      <PolarAngleAxis
        dataKey="name"
        // angleAxisId={0}
        domain={[0, 100]}
        stroke="#fcfcfc"
      />
      <Tooltip />
      {/* <PolarRadiusAxis /> */}

      <Radar dataKey="L12" stroke="#0cfaf9" fill="#0cfaf9" fillOpacity={0.8} />
      <Radar dataKey="L9" stroke="#8e3ec0" fill="#8E24AA" fillOpacity={0.8} />
    </RadarChart>
  );
};

export default SpiderChart;
