import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styles from "./analytics.module.css";

function CircularPercentage(props) {
  const percentage = 66;
  return (
    <div className={styles.circleDiv}>
      <Example label={props.data.Parameter}>
        <CircularProgressbar
          value={props.data.value}
          text={`${props.data.value}`}
          background
          backgroundPadding={6}
          styles={buildStyles({
            backgroundColor: "#1092de",
            textColor: "#0cfaf9",
            pathColor: `${props.data.fill}`,
            trailColor: "transparent",
          })}
        />
      </Example>
    </div>
  );
}

function Example(props) {
  return (
    <div className={styles.circleInnerCont}>
      <div className={styles.circleWidth}>{props.children}</div>
      <div style={{ alignSelf: "center" }}>
        <h5 style={{ color: "#dfdfdf" }} className="h5">
          {props.label}
        </h5>
        <p style={{ whiteSpace: "nowrap" }}>{props.description}</p>
      </div>
    </div>
  );
}

export default CircularPercentage;
