import styles from "./overviewCards.module.css";
import AreaChart from "./areaChart";
import { useEffect, useState } from "react";
import rat from "../images/rat.svg";
import thermometer from "../images/thermometer.svg";
import Population from "../images/population.svg";

function StackedChart(props) {
  const [width, setWidth] = useState(302);
  const images = [Population, Population, rat, thermometer];

  function hresize() {
    if (window.innerWidth <= 1311) {
      setWidth(530);
    } else if (window.innerWidth > 1311) {
      setWidth(302);
    }
  }

  window.addEventListener("resize", hresize);

  useEffect(() => {
    hresize();
  }, [width]);

  return (
    <section className={styles.overviewContDiv}>
      <div className={styles.overviewSubCont}>
        <div className={styles.subContTop}>
          <div className={styles.roundedDiv}>
            <img className={styles.img} src={images[props.i]} />
          </div>
          <div>
            <p style={{ color: "#dfdfdf", fontSize: "16px" }}>
              {props.stackedAreaChart.Parameter}
            </p>
          </div>
          <div>
            <p style={{ color: "#dfdfdf" }}></p>
          </div>
        </div>
        <div className={styles.subContWave}>
          <div
            style={{
              marginLeft: "7px",
              marginBottom: "7px",
              marginRight: "7px",
            }}
          >
            <AreaChart width={width} points={props.data} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default StackedChart;
