import styles from "../mainStyle.module.css";
import Analytics from "../Analytics/analytics";
import OverviewCards from "../overviewCards/overviewCards";
import Header from "../header/header";
import { useState } from "react";

function MainPage() {
  const [date, setDate] = useState({});

  const currDate = function (date, time) {
    console.log(time);
    var theDate = new Date(time * 1000);

    setDate({
      date: date,
      time: theDate,
    });
  };

  return (
    <div>
      <div className={styles.mainContainer1}>
        <Header date={date} />
        <OverviewCards />
        <Analytics currDate={currDate} />
      </div>
    </div>
  );
}

export default MainPage;
