import { RadialBarChart, RadialBar, PolarAngleAxis, Tooltip } from "recharts";
import styles from "./analytics.module.css";
import { useState, useEffect } from "react";

function RadialChart(props) {
  const [rWidth, setrWidth] = useState(80);
  const [rHeight, setrHeight] = useState(80);

  function hresize() {
    if (window.innerWidth > 1364) {
      setrWidth(80);
      setrHeight(80);
    } else if (window.innerWidth <= 592) {
      setrWidth(120);
      setrHeight(120);
    } else if (window.innerWidth > 592 && window.innerWidth <= 692) {
      setrWidth(150);
      setrHeight(150);
    } else if (window.innerWidth > 692 < window.innerWidth <= 860) {
      setrWidth(180);
      setrHeight(180);
    } else if (window.innerWidth > 860 && window.innerWidth <= 1364) {
      setrWidth(150);
      setrHeight(150);
    }
  }
  // const data = [
  //   { uv: 70, x: 100, fill: "#83a6ed" },
  //   { uv: 60, x: 100, fill: "#83a6ed" },
  //   { uv: 50, x: 100, fill: "#83a6ed" },
  //   { uv: 60, x: 100, fill: "#83a6ed" },
  // ];
  // console.log(props.dummy);

  function getDate(val) {
    let d;
    props.dummy.filter((el) => {
      if (el.value === val) {
        // console.log(el.date);
        d = el.date;
      }
    });
    return d;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: "white" }}>
          <p className="label">{`${"Date"} : ${getDate(payload[0].value)}`}</p>
          <p className="label">{`${"value"} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  window.addEventListener("resize", hresize);

  useEffect(() => {
    hresize();
  }, [setrHeight]);
  return (
    <div className={styles.radialContFlex}>
      <p style={{ color: "#dfdfdf" }}>{`${
        props?.dummy[6]?.value || props?.dummy[5]?.value || 0
      }%`}</p>
      <RadialBarChart width={rWidth} height={rHeight} data={props.dummy}>
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          dataKey={"uv"}
          angleAxisId={0}
          tick={false}
        />

        <RadialBar
          minAngle={15}
          dataKey="value"
          // name={props.dummy[0]?.date}
          angleAxisId={0}
        />
        <Tooltip content={<CustomTooltip label={props.dummy} />} />
      </RadialBarChart>
      <p style={{ whiteSpace: "noWrap", color: "#dfdfdf" }}>
        {props.dummy[0]?.parameter || props.dummy[0]?.Parameter}
      </p>
    </div>
  );
}

export default RadialChart;
