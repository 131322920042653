import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import styles from "./analytics.module.css";

function WeeklyEnergy(props) {
  return (
    <div className={styles.weeklyFlex}>
      <span style={{ fontSize: "1.05rem" }}>{props?.data?.value}</span>
      <Progress.Line
        percent={props.data.value}
        vertical={true}
        showInfo={false}
        // format={(percent) => `${percent.toFixed(0)}`}
      />
      <p style={{ color: "#0cfaf9" }}>{props.data.name}</p>
    </div>
  );
}

export default WeeklyEnergy;
