import React from "react";
import { PieChart, Pie, Tooltip } from "recharts";

const Donut = (props) => {
  // console.log(props.data);
  // Sample data

  return (
    <PieChart width={250} height={280}>
      <Pie
        data={props.data}
        dataKey="value"
        outerRadius={89}
        innerRadius={50}
        // fill="green"
      />
      <Tooltip />
    </PieChart>
  );
};

export default Donut;

// import React, { useEffect, useRef } from "react";
// import * as d3 from "d3";

// const Donut = (props) => {
//   const ref = useRef(null);

//   var clr = [
//     "#fbbf64",
//     "#f473b4",
//     "#156dd2",
//     "#39fdf2",
//     "#384862",
//     "#5c00d2",
//     "#757575",
//   ];
//   const createPie = d3
//     .pie()
//     .value((d) => d.value)
//     .sort(null);
//   const createArc = d3
//     .arc()
//     .innerRadius(props.innerRadius)
//     .outerRadius(props.outerRadius);
//   const colors = d3.scaleOrdinal(clr);
//   const format = d3.format(".2f");

//   useEffect(() => {
//     const data = createPie(props.data);
//     const group = d3.select(ref.current);
//     const groupWithData = group.selectAll("g.arc").data(data);

//     groupWithData.exit().remove();

//     const groupWithUpdate = groupWithData
//       .enter()
//       .append("g")
//       .attr("class", "arc");

//     const path = groupWithUpdate
//       .append("path")
//       .merge(groupWithData.select("path.arc"));

//     path
//       .attr("class", "arc")
//       .attr("d", createArc)
//       .attr("fill", (d, i) => colors(i));

//     const text = groupWithUpdate
//       .append("text")
//       .merge(groupWithData.select("text"));

//     text
//       .attr("text-anchor", "middle")
//       .attr("alignment-baseline", "middle")
//       .attr("transform", (d) => `translate(${createArc.centroid(d)})`)
//       .style("fill", "white")
//       .style("font-size", 10)
//       .text((d) => `${d.value}%`);
//   }, [props.data]);

//   return (
//     <svg
//       width={props.width}
//       height={props.height}
//       style={{ marginLeft: "4.2rem" }}
//     >
//       <g
//         ref={ref}
//         transform={`translate(${props.outerRadius} ${props.outerRadius})`}
//       />
//     </svg>
//   );
// };

// export default Donut;
