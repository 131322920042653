import styles from "./mainStyle.module.css";
import OverviewCards from "./overviewCards/overviewCards";
import Header from "./header/header";
import Analytics from "./Analytics/analytics";
import React from "react";
import {
  Switch,
  HashRouter,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import LandingPage from "./landingPage/landingPage";
import { useState, useEffect } from "react";
import MainPage from "./mainpage/mainPage";
import LoginPage from "./loginPage/loginPage";
import Protected from "./util/protected";

function App() {
  const [jwt, setJwt] = useState("");
  const [authenticated, setIsAuthenticated] = useState();

  useEffect(() => {
    let tkn = localStorage.getItem("token");
    if (tkn) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [jwt]);
  // console.log(authenticated);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/mainpage" element={<MainPage />} />
        <Route
          exact
          path="/home"
          element={
            <Protected isLoggedIn={authenticated ? true : false}>
              <LandingPage setIsAuthenticated={setIsAuthenticated} />
            </Protected>
          }
        />
        <Route
          exact
          path="/"
          element={<LoginPage authenticated={authenticated} setJwt={setJwt} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
