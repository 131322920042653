import styles from "./analytics.module.css";
import { PieChart } from "react-minimal-pie-chart";
import CircularPercentage from "./circularPercentage";
import LineChartGraph from "./lineChart";
import React from "react";
import RadialChart from "./radialChart";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import WeeklyEnergy from "./weeklyEnergy";
import Donut from "./donut";
import * as d3 from "d3";
import SpiderChart from "./spiderChart";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import { DATA } from "./DummyData";
import PercentCal, { RANGE } from "./PercentCal";
import BasicModal from "../modal/modal";

function Analytics(props) {
  const [date, onChange] = useState(new Date());
  const [oldDate, setOldDate] = useState();
  const [dWidth, setdWidth] = useState(190);
  const [dHeight, setdHeight] = useState(190);
  const [dinnerRadius, setdInnerRadius] = useState(50);
  const [dOuterRadius, setdOuterRadius] = useState(90);
  const [accessTkn, setAccessTkn] = useState("");
  const [macAdd, setMacAdd] = useState("");

  const [minuteDataFromApi, setminuteDataFromApi] = useState({});
  const [dayDataFromApi, setDayDataFromApi] = useState({});
  const [circularData, setCircularData] = useState();
  const [radialData, setRadialData] = useState([]);
  const [weekelyEneryData, setWeekelyEneryData] = useState({});
  const [resData, setResData] = useState({});
  const [donutData, setDonutData] = useState([]);
  const [cacheData, setCacheData] = useState([]);

  const [lastSixDays, setLastSixDay] = useState([]);
  const [lastSevenDays, setLastSevenDays] = useState([]);

  const [todayDate, setTodayDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [gotDataForMinute, setGotDataForMinute] = useState(false);
  const [gotDataForDay, setGotDataForDay] = useState(false);

  const uhooCode = "e49b3725e1a4dd73112a32babfafc16c23254aa98bef0eaf";
  const dataForAccess = new URLSearchParams();
  dataForAccess.append("code", uhooCode);

  function getFilteredData(object, ...keys) {
    return keys.reduce(
      (result, key) => ({ ...result, [key]: object[key] }),
      {}
    );
  }

  useEffect(() => {
    // console.log("1");

    const selectedDate = new Date(date);
    const selectedDateString = selectedDate.getDate().toString();

    const newWeeklyData = DATA.filter(
      (el) => el.Day == selectedDateString[selectedDateString.length - 1]
    );

    setWeekelyEneryData(newWeeklyData[0]);
    //fetching day wise data
    const lastSevenDays = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(date?.getTime() - i * 24 * 60 * 60 * 1000);
      const dateString = newDate.toISOString().slice(0, 10);
      lastSevenDays.push(dateString);
    }
    // console.log(lastSevenDays);
    setLastSevenDays(lastSevenDays);
    if (!macAdd || !accessTkn) return;
    getSevenDaysData(accessTkn, macAdd, lastSevenDays);
    deviceData(accessTkn, macAdd);
  }, [date, accessTkn, macAdd]);

  useEffect(() => {
    // console.log("2");
    let tmp = [];
    for (let index = 1; index < 7; index++) {
      const now = new Date();
      now.setDate(now.getDate() - index);
      const date = now.getDate().toString();
      tmp.push(date[date.length - 1]);
    }
    setLastSixDay(tmp);

    if (minuteDataFromApi) {
      const dataForCircular =
        todayDate.getDate() === date.getDate() ? resData : dayDataFromApi;

      const fcd = getFilteredData(
        dataForCircular,
        "airPressure",
        "humidity",
        "co2",
        "sound"
      );

      let cd = [];

      for (let key in fcd) {
        if (key === "airPressure") {
          cd.push({
            Parameter: "Air Pressure",
            value: PercentCal(fcd[key], RANGE.AIR_PRESSURE),
            fill: fcd[key] > 1013 ? "red" : "#0cfaf9",
          });
        } else if (key === "humidity") {
          cd.push({
            Parameter: "Humidity",
            value: fcd[key],
            fill: fcd[key] > 60 ? "red" : "#0cfaf9",
          });
        } else if (key === "co2") {
          cd.push({
            Parameter: "Carbon Dioixde",
            value: PercentCal(fcd[key], RANGE.CO2),
            fill: fcd[key] > 1000 ? "red" : "#0cfaf9",
          });
        } else if (key === "sound") {
          cd.push({
            Parameter: "Noise Index",
            value: PercentCal(fcd[key], RANGE.SOUND),
            fill: fcd[key] > 25 ? "red" : "#0cfaf9",
          });
        }
      }

      setCircularData(cd);
    }

    if (dayDataFromApi) {
      const frd = getFilteredData(
        dayDataFromApi,
        "airPressure",
        "humidity",
        "co2",
        "sound"
      );

      const lastSixDayData = DATA.filter((el) => lastSixDays.includes(el.Day));
      let copy = {
        "Air Pressure": [],
        Humidity: [],
        CO2: [],
        "Noise Index": [],
      };
      // for (let a of lastSixDayData) {
      //   for (let key in copy) {
      //     let value;
      //     if (key == "Air Pressure") {
      //       value = PercentCal(a[key], RANGE.AIR_PRESSURE);
      //     } else if (key == "Humidity") {
      //       value = a[key];
      //     } else if (key == "CO2") {
      //       value = PercentCal(a[key], RANGE.CO2);
      //     } else if (key == "Noise Index") {
      //       value = PercentCal(a[key], RANGE.SOUND);
      //     }

      //     copy[key].push({
      //       parameter: key,
      //       value: value,
      //       fill: "#0cfaf9",
      //     });
      //   }
      // }

      // let rd = [];

      // for (let key in frd) {
      //   if (key === "airPressure") {
      //     rd.push([
      //       ...copy["Air Pressure"],
      //       {
      //         Parameter: "Air Pressure",
      //         value: PercentCal(frd[key], RANGE.AIR_PRESSURE),
      //         fill: "#0cfaf9",
      //       },
      //     ]);
      //   } else if (key === "humidity") {
      //     rd.push([
      //       ...copy["Humidity"],
      //       {
      //         Parameter: "Humidity",
      //         value: frd[key],
      //         fill: "#0cfaf9",
      //       },
      //     ]);
      //   } else if (key === "co2") {
      //     rd.push([
      //       ...copy.CO2,
      //       {
      //         Parameter: "Carbon Dioixde",
      //         value: PercentCal(frd[key], RANGE.CO2),
      //         fill: "#0cfaf9",
      //       },
      //     ]);
      //   } else if (key === "sound") {
      //     rd.push([
      //       ...copy["Noise Index"],
      //       {
      //         Parameter: "Noise Index",
      //         value: PercentCal(frd[key], RANGE.SOUND),
      //         fill: "#0cfaf9",
      //       },
      //     ]);
      //   }
      // }

      // setRadialData(rd);
    }
  }, [dayDataFromApi, minuteDataFromApi]);

  ///////////////////////////

  useEffect(() => {
    axios
      .post(`https://api.uhooinc.com/v1/generatetoken`, dataForAccess)
      .then((res) => {
        setAccessTkn(res.data["access_token"]);
        deviceList(res.data["access_token"]);
      });
  }, []);

  function deviceList(accTkn) {
    axios
      .get(`https://api.uhooinc.com/v1/devicelist`, {
        headers: {
          Authorization: `Bearer ${accTkn}`,
        },
      })
      .then((res) => {
        setMacAdd(res.data[0].macAddress);
        deviceData(accTkn, res.data[0].macAddress);
      });
  }

  useEffect(() => {
    // console.log("3");
    const radialData = cacheData;
    // console.log(radialData);
    let copy = {
      airPressure: [],
      humidity: [],
      co2: [],
      sound: [],
    };
    let ai = 0;
    let hi = 0;
    let ci = 0;
    let ni = 0;
    let index;
    for (let a of radialData || []) {
      for (let key in copy) {
        let value;
        let copyKey;

        if (key == "airPressure") {
          copyKey = "Air Pressure";
          value = PercentCal(a[key], RANGE.AIR_PRESSURE);
          index = ai;
          ai = ai + 1;
        } else if (key == "humidity") {
          copyKey = "Humidity";
          value = a[key];
          index = hi;
          hi = hi + 1;
        } else if (key == "co2") {
          copyKey = "Co2";
          value = PercentCal(a[key], RANGE.CO2);
          index = ci;
          ci = ci + 1;
        } else if (key == "sound") {
          copyKey = "Noise Index";
          value = PercentCal(a[key], RANGE.SOUND);
          index = ni;
          ni = ni + 1;
        }

        copy[key].push({
          parameter: copyKey,
          value: value,
          fill: "#0cfaf9",
          date: lastSevenDays[index],
        });
      }
    }

    let rd = [];

    for (let key in copy) {
      if (key === "airPressure") {
        rd.push([...copy["airPressure"]]);
      } else if (key === "humidity") {
        rd.push([...copy["humidity"]]);
      } else if (key === "co2") {
        rd.push([...copy.co2]);
      } else if (key === "sound") {
        rd.push([...copy["sound"]]);
      }
    }

    setRadialData(rd);
  }, [cacheData, date]);

  function deviceData(accTkn, macAd) {
    // console.log("4");

    const modes = ["day"];
    const mode = ["minute"];

    const cacheData = JSON.parse(localStorage.getItem("sevenDayData"));

    const currDate = date.toISOString().slice(0, 10);
    // console.log(date.toISOString());

    console.log(todayDate.getDate(), date.getDate());

    if (todayDate.getDate() === date.getDate()) {
      const bodyData = [];
      mode.forEach((el, i) => {
        let data = new URLSearchParams();
        data.append("macAddress", macAd);
        data.append("mode", el);
        data.append("prevDateTime", `${currDate} 00:00:00`);

        bodyData.push(data);
      });
      // const found = cacheData?.find((item) => item.date == currDate);

      // if (found) {
      //   setDayDataFromApi(found);
      //   setminuteDataFromApi(found);
      //   setResData(found);
      //   return;
      // }

      bodyData.forEach((el, i) => {
        axios
          .post(`https://api.uhooinc.com/v1/devicedata`, el, {
            headers: {
              Authorization: `Bearer ${accTkn}`,
            },
          })
          .then((res) => {
            console.log("i1", "minute-first");
            setminuteDataFromApi(res.data.data[0]);
            // setminuteDataFromApi(res.data.data[0]);
            setResData(res.data.data[0]);
            setGotDataForMinute(true);
          })
          .catch((err) => {
            console.log("i2,'minute-first-err");
            console.log(err);
            setModal(true);
            if (i === 0) {
              setDayDataFromApi(minuteDataFromApi);
              setGotDataForMinute(false);
            } else {
              setDayDataFromApi((oldData) => oldData);
              setminuteDataFromApi((oldData) => oldData);
              setResData((oldData) => oldData);
            }
          });
      });
    }

    if (todayDate.getDate() !== date.getDate()) {
      const bodyData = [];
      modes.forEach((el, i) => {
        let data = new URLSearchParams();
        data.append("macAddress", macAd);
        data.append("mode", el);
        data.append("prevDateTime", `${currDate} 00:00:00`);

        bodyData.push(data);
      });

      bodyData.forEach((el, i) => {
        axios
          .post(`https://api.uhooinc.com/v1/devicedata`, el, {
            headers: {
              Authorization: `Bearer ${accTkn}`,
            },
          })
          .then((res) => {
            console.log("i3", "day-second");
            setDayDataFromApi(res.data.data[0]);
            console.log(res.data.data);
            setResData(res.data.data[0]);
            setGotDataForMinute(true);
            changeDateAndTime();
          })
          .catch((err) => {
            console.log("i4", "day-second-err");
            console.log(err);
            setModal(true);
            if (i === 0) {
              setDayDataFromApi(minuteDataFromApi);
              setGotDataForMinute(false);
            } else {
              setDayDataFromApi((oldData) => oldData);
              setminuteDataFromApi((oldData) => oldData);
              setResData((oldData) => oldData);
            }
          });
      });
    }
  }

  function changeDateAndTime() {
    // console.log(gotDataForDay, gotDataForMinute);
    // if (gotDataForDay && gotDataForMinute) {
    //   const dataBasedOnDate =
    //     todayDate.getDate() === date.getDate() ? resData : dayDataFromApi;
    //   props.currDate(date, dataBasedOnDate?.timestamp);
    //   console.log(dayDataFromApi);
    //   // console.log(dataBasedOnDate);
    // }
  }

  useEffect(() => {
    console.log(gotDataForDay, gotDataForMinute);
    if (gotDataForDay && gotDataForMinute) {
      const dataBasedOnDate =
        todayDate.getDate() === date.getDate() ? resData : dayDataFromApi;
      props.currDate(date, dataBasedOnDate?.timestamp);
    }
  }, [gotDataForDay, gotDataForMinute, dayDataFromApi, minuteDataFromApi]);

  // console.log(resData);
  function getSevenDaysData(accTkn, macAd, sevenDays) {
    // console.log("5");
    let tmp = [];
    const cacheData = JSON.parse(localStorage.getItem("sevenDayData"));

    let foundData = [];

    for (let el in sevenDays) {
      // console.log(sevenDays[el]);
      const found = cacheData?.find((item) => item.date == sevenDays[el]);
      if (found !== undefined) {
        foundData.push(found);
      }
      // console.log(found);
      if (found) {
        console.log("g1", "found");
        setGotDataForDay(true);
        changeDateAndTime();
        tmp.push(found);
        localStorage.setItem("sevenDayData", JSON.stringify(tmp));
        setCacheData(tmp);
      }

      // console.log(foundData);
      setTimeout(() => {
        if (foundData.length === 0) {
          console.log("g2");
          const sevenDayPayload = new URLSearchParams();
          sevenDayPayload.append("macAddress", macAd);
          sevenDayPayload.append("mode", "day");
          sevenDayPayload.append("prevDateTime", `${sevenDays[el]} 00:00:00`);
          axios
            .post(`https://api.uhooinc.com/v1/devicedata`, sevenDayPayload, {
              headers: {
                Authorization: `Bearer ${accTkn}`,
              },
            })
            .then((res) => {
              tmp.push({
                ...res?.data?.data[0],
                date: sevenDays[el],
              });
              localStorage.setItem("sevenDayData", JSON.stringify(tmp));
              setCacheData(tmp);
              // setOldDate(date);
              setGotDataForDay(true);
              console.log("g2", "res");
            })
            .catch((err) => {
              // onChange(oldDate);
              setGotDataForDay(false);
              console.log(err);
              console.log("g3", "err");
              // tmp.push(found);
              setCacheData(cacheData);
              setModal(true);
            });
        }
      }, 2000);
    }
    // console.log(tmp);
  }

  function hresize() {
    if (window.innerWidth <= 860) {
      setdWidth(340);
      setdHeight(350);
      setdInnerRadius(90);
      setdOuterRadius(160);
    } else if (window.innerWidth > 860 && window.innerWidth <= 893) {
      setdWidth(280);
      setdHeight(250);
      setdInnerRadius(70);
      setdOuterRadius(115);
    } else if (window.innerWidth > 893 && window.innerWidth <= 988) {
      setdWidth(280);
      setdHeight(270);
      setdInnerRadius(70);
      setdOuterRadius(125);
    } else if (window.innerWidth > 988 && window.innerWidth <= 1138) {
      setdWidth(300);
      setdHeight(300);
      setdInnerRadius(80);
      setdOuterRadius(136);
    } else if (window.innerWidth > 1138 && window.innerWidth <= 1233) {
      setdWidth(350);
      setdHeight(310);
      setdInnerRadius(80);
      setdOuterRadius(140);
    } else if (window.innerWidth > 1233 && window.innerWidth <= 1292) {
      setdWidth(390);
      setdHeight(310);
      setdInnerRadius(80);
      setdOuterRadius(150);
    } else if (window.innerWidth > 1292 && window.innerWidth <= 1316) {
      setdWidth(390);
      setdHeight(310);
      setdInnerRadius(80);
      setdOuterRadius(150);
    } else if (window.innerWidth > 1316 && window.innerWidth <= 1364) {
      setdWidth(390);
      setdHeight(330);
      setdInnerRadius(100);
      setdOuterRadius(160);
    } else if (window.innerWidth > 1364) {
      setdWidth(340);
      setdHeight(180);
      setdInnerRadius(50);
      setdOuterRadius(88);
    }
  }
  window.addEventListener("resize", hresize);

  useEffect(() => {
    hresize();
  }, [setdHeight]);

  const data = [
    { name: "A", x: 1, fill: "green" },
    { name: "B", x: 2, fill: "yellow" },
    { name: "C", x: 3, fill: "aqua" },
    { name: "D", x: 4, fill: "blue" },
    { name: "E", x: 5, fill: "orange" },
    { name: "F", x: 6, fill: "red" },
    { name: "G", x: 2, fill: "black" },
  ];

  const dataForDonut =
    todayDate.getDate() === date?.getDate() ? resData : dayDataFromApi;

  useEffect(() => {
    const pm25 = PercentCal(dataForDonut?.pm25, RANGE.PM);
    const co2 = PercentCal(dataForDonut?.co2, RANGE.CO2);
    const co = PercentCal(dataForDonut?.co, RANGE.CO);
    const ozone = dataForDonut?.ozone;
    const tvoc = PercentCal(dataForDonut?.tvoc, RANGE.TVOC);
    const ch2o = PercentCal(dataForDonut?.ch2o, RANGE.CH2O);
    const other = 100 - (pm25 + co2 + co + ozone + tvoc + ch2o);

    const copy = [
      {
        value: pm25,
        name: "PM2.5",
        fill: "#fbbf64",
      },
      {
        value: co2,
        name: "Carbon Dioxide",
        fill: "#f473b4",
      },
      {
        value: co,
        name: "Carbon Monoxide",
        fill: "#156dd2",
      },
      {
        value: ozone,
        name: "Ozone",
        fill: "#39fdf2",
      },
      {
        value: tvoc,
        name: "TVOC",
        fill: "#384862",
      },
      {
        value: ch2o,
        name: "Farmaldehyde",
        fill: "#5c00d2",
      },
      {
        value: other,
        name: "Others",
        fill: "#757575",
      },
    ];
    setDonutData(copy);
  }, [resData]);

  return (
    <>
      {modal && <BasicModal setModal={setModal} />}
      <section className={styles.analyticsCont}>
        <div className={styles.donutandSpider}>
          <div className={styles.donutCont}>
            <Donut
              data={donutData}
              width={dWidth}
              height={dHeight}
              innerRadius={dinnerRadius}
              outerRadius={dOuterRadius}
            />
            <div className={styles.dlWidth}>
              <div className={styles.donutLegend}>
                {donutData.map((param, i) => (
                  <div className={styles.donutLegendDiv}>
                    <div
                      className={styles.roundedDiv}
                      style={{ backgroundColor: `${param.fill}` }}
                    ></div>
                    <div>
                      <p style={{ color: "#dfdfdf", whiteSpace: "nowrap" }}>
                        {param.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.spiderCont}>
            <SpiderChart
              resData={resData}
              todayDate={todayDate}
              date={date}
              dayData={dayDataFromApi}
            />
          </div>
        </div>
        <div className={styles.circleandGraph}>
          <div className={styles.circleCont}>
            {circularData &&
              circularData.map((param, i) => (
                <CircularPercentage data={param} />
              ))}
          </div>
          <div className={styles.lineChartCont}>
            <LineChartGraph />
            <div className={styles.lineChartP}>
              <span style={{ color: "#ffdfdf" }}>
                {" "}
                Hourly EnergY Usage (kWh) for previous day
              </span>
            </div>
          </div>
        </div>
        <div className={styles.radialandCalender}>
          <div className={styles.radialCont}>
            {radialData?.map((param, i) => (
              <RadialChart
                dummy={param}
                data={data}
                lastSevenDays={lastSevenDays}
              />
            ))}
          </div>

          <div className={styles.calendarCont}>
            <Calendar
              onChange={onChange}
              showWeekNumbers
              showFixedNumberOfWeeks
              value={date}
              // showNavigation={false}
            />
            <div className={styles.weeklyCont}>
              {weekelyEneryData?.WeeklyEnergydata?.map((param, i) => (
                <WeeklyEnergy data={param} />
              ))}
            </div>
            <div className={styles.weeklyP}>
              <span style={{ color: "#dfdfdf" }}>Weekly Energy Usage</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Analytics;
