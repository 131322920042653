import { useEffect, useState } from "react";
import meinhardt from "../../images/meinhardt-logo.png";
import { useNavigate } from "react-router-dom";
import building from "../../images/building.svg";
import styles from "./header.module.css";

function Header(props) {
  const [date, setDate] = useState(`-/-/-`);
  const [time, setTime] = useState("00:00");
  const navigate = useNavigate();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  useEffect(() => {
    if (props.date) {
      const editedDate = `${props?.date?.getDate()}-${props?.date?.toLocaleString(
        "default",
        { month: "long" }
      )}-${props?.date?.getFullYear()}`;

      setDate(editedDate);
    }
  }, [props.date]);

  // console.log(props.date);

  const handleLogout = () => {
    props.setIsAuthenticated(false);
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className={styles.headerCont}>
      <img
        src={meinhardt}
        style={{ cursor: "pointer" }}
        onClick={() => setShowLogoutPopup(!showLogoutPopup)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "15px",
        }}
      >
        <a href="https://app-portal-cn5.envisioniot.com/portal/abd255dd-bf53-4451-980c-a454b00121b7/fleet_single_building_overview">
          <img src={building} height={30} width={40} />
        </a>
        <span style={{ color: "white", fontSize: "11px" }}>Shanghai</span>
      </div>

      <div className={styles.dateCont}>
        <p>Viewing data for : </p>
        <h6>{`\u00A0${date}`}</h6>
      </div>
      {showLogoutPopup && ( // Conditionally render the logout popup
        <div className={styles.logoutPopup}>
          <p onClick={handleLogout}>Logout</p>
        </div>
      )}
    </div>
  );
}

export default Header;
