import React from "react";

export const RANGE = {
  TEMP: 125,
  CO2: 9600,
  CO: 1000,
  CH2O: 2000,
  TVOC: 60000,
  AIR_PRESSURE: 1070,
  SOUND: 5,
  PM: 1000,
};

const PercentCal = (x, range = 1) => {
  const value = Math.round(((100 * x) / range) * 100) / 100;
  return value;
};

export default PercentCal;
