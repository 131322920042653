import styles from "./leftPanelSubCont1.module.css";
import thermometerlan from "../../../../images/thermometerlan.svg";
import ratlan from "../../../../images/ratlan.svg";
import fire from "../../../../images/fire.svg";
import CountUp, { useCountUp } from "react-countup";
import { useEffect } from "react";

function LeftPanelSubCont1(props) {
  useEffect(() => {
    let color =
      props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
        ?.temperature >= 18 &&
      props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
        ?.temperature <= 20
        ? "#17e117d1"
        : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
            ?.temperature >= 21 &&
          props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
            ?.temperature <= 25
        ? "yellow"
        : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
            ?.temperature > 25
        ? "#ff5a2d"
        : "white";

    let offcIndexSum1 = 0;

    if (color && color === "#17e117d1") {
      offcIndexSum1++;
    }
    props.fetchOfficeIndex1(offcIndexSum1);
  }, [props.deviceData]);

  return (
    <div class={styles.LeftPanelSubCont1}>
      <div class={styles.sub1}>
        <div class={styles.flexRow}>
          <span
            style={{
              color: "white",
              fontSize: "20px",
              fontWeight: "800",
              whiteSpace: "nowrap",
              wordSpacing: "5px",
            }}
          >
            Overall Office Index
          </span>
          <span
            style={{
              color:
                props.overallOfficeIndex < 5
                  ? "#ff4500"
                  : props.overallOfficeIndex >= 5 &&
                    props.overallOfficeIndex <= 9
                  ? "Yellow"
                  : props.overallOfficeIndex >= 10
                  ? "#17e117d1"
                  : "white",
              fontSize: "28px",
              fontWeight: "900",
            }}
          >
            {props.overallOfficeIndex < 5
              ? "Bad"
              : props.overallOfficeIndex >= 5 && props.overallOfficeIndex <= 9
              ? "Average"
              : props.overallOfficeIndex >= 10
              ? "Good"
              : ""}
          </span>
        </div>

        <div class={styles.flexRow1}>
          <img src={thermometerlan} width="40%" height="80%" />
          <span
            style={{
              color:
                props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
                  ?.temperature >= 18 &&
                props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
                  ?.temperature <= 20
                  ? "#17e117d1"
                  : props.deviceData?.[
                      `${props.mode === "day" ? "day" : "minute"}`
                    ]?.temperature >= 21 &&
                    props.deviceData?.[
                      `${props.mode === "day" ? "day" : "minute"}`
                    ]?.temperature <= 25
                  ? "yellow"
                  : props.deviceData?.[
                      `${props.mode === "day" ? "day" : "minute"}`
                    ]?.temperature > 25
                  ? "#ff4500"
                  : "white",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            {" "}
            <CountUp
              style={{ fontSize: "1.2rem", fontWeight: "600" }}
              end={
                props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
                  ?.temperature || 0
              }
              delay={2}
            />
            °c
          </span>
        </div>
      </div>
      <div class={styles.sub2}>
        <img src={ratlan} width="35%" height="60%" />
        <span>No Alert</span>
      </div>
      <div class={styles.sub3}>
        <img src={fire} width="35%" height="60%" />
        <span>No Alert</span>
      </div>
    </div>
  );
}

export default LeftPanelSubCont1;
