import LeftPanelSubCont1 from "./leftSubPanels/leftPanelSubCont1/leftPanelSubCont1";
import LeftPanelSubCont2 from "./leftSubPanelCont2/leftPanelSubCont2";
import LeftSubPanelCont3 from "./leftSubPanelCont3/leftSubPanelCont3";
import styles from "./leftPanel.module.css";
import { useState } from "react";
import { useEffect } from "react";

function LeftPanel(props) {
  const [officeIndex1, setOfficeIndex1] = useState();
  const [officeIndex2, setOfficeIndex2] = useState();
  const [overallOfficeIndex, setOverallOfficeIndex] = useState();

  function fetchOfficeIndex1(offcIndexSum1) {
    setOfficeIndex1(offcIndexSum1);
  }

  function fetchOfficeIndex2(offcIndexSum2) {
    setOfficeIndex2(offcIndexSum2);
  }

  useEffect(() => {
    let defaultIndex = 5;
    setOverallOfficeIndex(defaultIndex + officeIndex1 + officeIndex2);
  }, [officeIndex1, officeIndex2]);

  return (
    <div class={styles.leftPanelCont}>
      <div class={styles.leftPanelContSub1}>
        <LeftPanelSubCont1
          fetchOfficeIndex1={fetchOfficeIndex1}
          deviceData={props.deviceData}
          overallOfficeIndex={overallOfficeIndex}
          mode={props.mode}
        />
      </div>
      <div class={styles.leftPanelContSub2}>
        <LeftPanelSubCont2
          fetchOfficeIndex2={fetchOfficeIndex2}
          deviceData={props.deviceData}
          mode={props.mode}
        />
      </div>
      <div class={styles.leftPanelContSub3}>
        <LeftSubPanelCont3 chartData={props.chartData} />
      </div>
    </div>
  );
}

export default LeftPanel;
