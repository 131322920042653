export const DATA = [
  {
    Day: "9",
    Temp: "24.3",
    "Air Pressure": "1007",
    Humidity: "62",
    CO2: "758",
    "PM2.5": "18",
    "Energy kWh": "77.4",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "48",
        name: "M",
      },
      {
        value: "92",
        name: "T",
      },
      {
        value: "02",
        name: "W",
      },
      {
        value: "32",
        name: "T",
      },
      {
        value: "77",
        name: "F",
      },
      {
        value: "43",
        name: "S",
      },
      {
        value: "45",
        name: "S",
      },
    ],
  },
  {
    Day: "8",
    Temp: "25.1",
    "Air Pressure": "1008",
    Humidity: "63",
    CO2: "825",
    "PM2.5": "20",
    "Energy kWh": "80.7",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "72",
        name: "M",
      },
      {
        value: "22",
        name: "T",
      },
      {
        value: "12",
        name: "W",
      },
      {
        value: "82",
        name: "T",
      },
      {
        value: "32",
        name: "F",
      },
      {
        value: "63",
        name: "S",
      },
      {
        value: "45",
        name: "S",
      },
    ],
  },
  {
    Day: "7",
    Temp: "25.6",
    "Air Pressure": "1007",
    Humidity: "65",
    CO2: "856",
    "PM2.5": "21",
    "Energy kWh": "79.6",
    "Noise Index": "3",
    WeeklyEnergydata: [
      {
        value: "48",
        name: "M",
      },
      {
        value: "92",
        name: "T",
      },
      {
        value: "02",
        name: "W",
      },
      {
        value: "32",
        name: "T",
      },
      {
        value: "77",
        name: "F",
      },
      {
        value: "43",
        name: "S",
      },
      {
        value: "45",
        name: "S",
      },
    ],
  },
  {
    Day: "6",
    Temp: "25.8",
    "Air Pressure": "1008",
    Humidity: "62",
    CO2: "849",
    "PM2.5": "19",
    "Energy kWh": "78.2",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "36",
        name: "M",
      },
      {
        value: "89",
        name: "T",
      },
      {
        value: "43",
        name: "W",
      },
      {
        value: "22",
        name: "T",
      },
      {
        value: "32",
        name: "F",
      },
      {
        value: "63",
        name: "S",
      },
      {
        value: "15",
        name: "S",
      },
    ],
  },
  {
    Day: "5",
    Temp: "24.9",
    "Air Pressure": "1009",
    Humidity: "59",
    CO2: "875",
    "PM2.5": "18",
    "Energy kWh": "75.1",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "10",
        name: "M",
      },
      {
        value: "22",
        name: "T",
      },
      {
        value: "99",
        name: "W",
      },
      {
        value: "72",
        name: "T",
      },
      {
        value: "12",
        name: "F",
      },
      {
        value: "63",
        name: "S",
      },
      {
        value: "45",
        name: "S",
      },
    ],
  },
  {
    Day: "4",
    Temp: "24.6",
    "Air Pressure": "1007",
    Humidity: "60",
    CO2: "798",
    "PM2.5": "16",
    "Energy kWh": "80.3",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "33",
        name: "M",
      },
      {
        value: "71",
        name: "T",
      },
      {
        value: "31",
        name: "W",
      },
      {
        value: "82",
        name: "T",
      },
      {
        value: "40",
        name: "F",
      },
      {
        value: "56",
        name: "S",
      },
      {
        value: "83",
        name: "S",
      },
    ],
  },
  {
    Day: "3",
    Temp: "24.5",
    "Air Pressure": "1010",
    Humidity: "62",
    CO2: "806",
    "PM2.5": "19",
    "Energy kWh": "83.6",
    "Noise Index": "3",
    WeeklyEnergydata: [
      {
        value: "32",
        name: "M",
      },
      {
        value: "52",
        name: "T",
      },
      {
        value: "62",
        name: "W",
      },
      {
        value: "42",
        name: "T",
      },
      {
        value: "38",
        name: "F",
      },
      {
        value: "23",
        name: "S",
      },
      {
        value: "55",
        name: "S",
      },
    ],
  },
  {
    Day: "2",
    Temp: "25.1",
    "Air Pressure": "1008",
    Humidity: "58",
    CO2: "826",
    "PM2.5": "17",
    "Energy kWh": "81.9",
    "Noise Index": "3",
    WeeklyEnergydata: [
      {
        value: "65",
        name: "M",
      },
      {
        value: "52",
        name: "T",
      },
      {
        value: "62",
        name: "W",
      },
      {
        value: "22",
        name: "T",
      },
      {
        value: "94",
        name: "F",
      },
      {
        value: "27",
        name: "S",
      },
      {
        value: "50",
        name: "S",
      },
    ],
  },
  {
    Day: "1",
    Temp: "24.7",
    "Air Pressure": "1009",
    Humidity: "57",
    CO2: "795",
    "PM2.5": "18",
    "Energy kWh": "77.4",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "32",
        name: "M",
      },
      {
        value: "52",
        name: "T",
      },
      {
        value: "62",
        name: "W",
      },
      {
        value: "42",
        name: "T",
      },
      {
        value: "38",
        name: "F",
      },
      {
        value: "23",
        name: "S",
      },
      {
        value: "55",
        name: "S",
      },
    ],
  },
  {
    Day: "0",
    Temp: "25.3",
    "Air Pressure": "1010",
    Humidity: "61",
    CO2: "817",
    "PM2.5": "20",
    "Energy kWh": "79.8",
    "Noise Index": "2",
    WeeklyEnergydata: [
      {
        value: "66",
        name: "M",
      },
      {
        value: "90",
        name: "T",
      },
      {
        value: "62",
        name: "W",
      },
      {
        value: "95",
        name: "T",
      },
      {
        value: "36",
        name: "F",
      },
      {
        value: "48",
        name: "S",
      },
      {
        value: "55",
        name: "S",
      },
    ],
  },
];
