import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import styles from "./analytics.module.css";
import { useState, useEffect } from "react";

function LineChartGraph() {
  const data = [
    {date:"0",L9:67.5,L12:82.6,y:0},
    {date:"1",L9:43.2,L12:59.9,y:100},
    {date:"2",L9:42.5,L12:52.0,y:120},
    {date:"3",L9:40.4,L12:49.2,y:120},
    {date:"4",L9:40.6,L12:49.8,y:120},
    {date:"5",L9:45.5,L12:50.2,y:120},
    {date:"6",L9:61.1,L12:70.4,y:120},
    {date:"7",L9:67.0,L12:86.2,y:120},
    {date:"8",L9:65.3,L12:89.0,y:120},
    {date:"9",L9:70.2,L12:90.1,y:120},
    {date:"10",L9:68.2,L12:88.8,y:120},
    {date:"11",L9:69.2,L12:89.1,y:120},
    {date:"12",L9:66.1,L12:82.0,y:120},
    {date:"13",L9:68.6,L12:89.2,y:120},
    {date:"14",L9:70.0,L12:89.8,y:120},
    {date:"15",L9:69.5,L12:86.2,y:120},
    {date:"16",L9:70.1,L12:88.4,y:120},
    {date:"17",L9:67.0,L12:86.2,y:120},
    {date:"18",L9:65.3,L12:89.0,y:120},
    {date:"19",L9:70.2,L12:90.1,y:120},
    {date:"20",L9:68.2,L12:88.8,y:120},
    {date:"21",L9:69.2,L12:89.1,y:120},
    {date:"22",L9:59.1,L12:76.0,y:120},
    {date:"23",L9:56.6,L12:72.2,y:120},
  ];

  const [lWidth, setlWidth] = useState(638);
  const [lHeight, setlHeight] = useState(250);

  function hresize() {
    if (window.innerWidth <= 527) {
      setlWidth(400);
      setlHeight(300);
    } else if (window.innerWidth > 1115 && window.innerWidth <= 1152) {
      setlWidth(950);
      setlHeight(350);
    } else if (window.innerWidth > 527 && window.innerWidth <= 612) {
      setlWidth(450);
      setlHeight(300);
    } else if (window.innerWidth > 612 && window.innerWidth <= 641) {
      setlWidth(510);
      setlHeight(300);
    } else if (window.innerWidth > 641 && window.innerWidth <= 688) {
      setlWidth(560);
      setlHeight(300);
    } else if (window.innerWidth > 688 && window.innerWidth <= 705) {
      setlWidth(600);
      setlHeight(300);
    } else if (window.innerWidth > 705 && window.innerWidth <= 773) {
      setlWidth(630);
      setlHeight(300);
    } else if (window.innerWidth > 773 && window.innerWidth <= 791) {
      setlWidth(680);
      setlHeight(300);
    } else if (window.innerWidth > 786 && window.innerWidth <= 878) {
      setlWidth(700);
      setlHeight(300);
    } else if (window.innerWidth > 878 && window.innerWidth <= 860) {
      setlWidth(730);
      setlHeight(300);
    } else if (window.innerWidth > 1364) {
      setlWidth(638);
      setlHeight(250);
    } else if (window.innerWidth > 860 && window.innerWidth <= 939) {
      setlWidth(780);
      setlHeight(450);
    } else if (window.innerWidth > 939 && window.innerWidth <= 1011) {
      setlWidth(820);
      setlHeight(450);
    } else if (window.innerWidth > 1319 && window.innerWidth <= 1364) {
      setlWidth(680);
      setlHeight(350);
    } else if (window.innerWidth <= 1319 && window.innerWidth > 1269) {
      setlWidth(650);
      setlHeight(350);
    } else if (window.innerWidth >= 1269 && window.innerWidth < 1319) {
      setlWidth(630);
      setlHeight(350);
    } else if (window.innerWidth >= 1236 && window.innerWidth < 1269) {
      setlWidth(600);
      setlHeight(350);
    } else if (window.innerWidth >= 1186 && window.innerWidth < 1236) {
      setlWidth(580);
      setlHeight(350);
    } else if (window.innerWidth >= 1152 && window.innerWidth < 1186) {
      setlWidth(550);
      setlHeight(350);
    } else if (window.innerWidth > 860 && window.innerWidth <= 1116) {
      setlWidth(900);
      setlHeight(450);
    }
  }

  window.addEventListener("resize", hresize);

  useEffect(() => {
    hresize();
  }, [setlHeight]);

  return (
    <div className={styles.lineChartdiv}>
      <div className="App">
        <LineChart
          width={lWidth}
          height={lHeight}
          data={data}
          margin={{ top: 20, right: 25, left: 2, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="date" stroke="#E8EAF6"/>
          <YAxis dataKey="y"  stroke="#E8EAF6"/>
          <Tooltip />
          {/* <Legend /> */}
          <Line type="monotone" dataKey="L9" strokeWidth={3} stroke="#42A5F5"/>
          <Line type="monotone" dataKey="L12" strokeWidth={3} stroke="#ef4fa6" />
        </LineChart>
      </div>
    </div>
  );
}

export default LineChartGraph;
