import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./loginPage.module.css";
import axios from "axios";

const LoginPage = (props) => {
  const videoRef = useRef(null);
  // const popupRef = useRef(null);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const isiPhone = () => {
    return /iPhone/.test(navigator.userAgent);
  };
  const isUseriPhone = isiPhone();

  const handleVideoEnd = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const video = videoRef?.current;

    const handlePlay = () => {
      video?.play();
    };

    const handleClick = () => {
      if (video.ended) return;
      video?.play();
    };

    const timeout = setTimeout(handlePlay, 2000);

    video?.addEventListener("click", handleClick);
    video?.addEventListener("ended", handleVideoEnd);
    video?.addEventListener("play", () => {
      setShowPopup(false);
    });

    return () => {
      clearTimeout(timeout);
      // video.removeEventListener("click", handleClick);
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    if (!validateEmail(inputValue)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    if (!validatePassword(inputValue)) {
      setPasswordError("Password must be at least 6 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email) && validatePassword(password)) {
      axios
        .post("https://energeek.starlly.in/api/user/login", {
          loginId: email,
          password: password,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            props.setJwt(response.data.token);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Invalid email or password");
    }
  };

  useEffect(() => {
    if (props.authenticated) {
      navigate("/home");
    }
  }, [props.authenticated]);

  return (
    <>
      {isUseriPhone ? (
        <div className={styles.loginCont}>
          <div
            // ref={popupRef}
            style={{
              position: "absolute",
              top: "50vh",
              left: "50vw",
              transform: "translate(-50%, -50%)",
              backgroundColor: "transparent",
              padding: "20px",
              borderRadius: "5px",
              color: "white",
              zIndex: "30",
            }}
          >
            <div className={styles.popup} id="popup">
              <h3>Login</h3>
              <form onSubmit={handleSubmit}>
                <div className={styles.errMsg}>
                  <input
                    spellCheck={false}
                    type="text"
                    id="username"
                    name="username"
                    placeholder="username"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && <span>{emailError}</span>}
                </div>

                <div className={styles.errMsg}>
                  <input
                    placeholder="password"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {passwordError && <span>{passwordError}</span>}
                </div>

                <input type="submit" value="Login" />
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            zIndex: "10",
            height: "100%",
            width: "100%",
          }}
        >
          {showPopup && (
            <div
              // ref={popupRef}
              style={{
                position: "absolute",
                top: "50vh",
                left: "50vw",
                transform: "translate(-50%, -50%)",
                backgroundColor: "transparent",
                padding: "20px",
                borderRadius: "5px",
                color: "white",
                zIndex: "30",
              }}
            >
              <div className={styles.popup} id="popup">
                <h3>Login</h3>
                <form onSubmit={handleSubmit}>
                  <div className={styles.errMsg}>
                    <input
                      spellCheck={false}
                      type="text"
                      id="username"
                      name="username"
                      placeholder="username"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && <span>{emailError}</span>}
                  </div>

                  <div className={styles.errMsg}>
                    <input
                      placeholder="password"
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && <span>{passwordError}</span>}
                  </div>

                  <input type="submit" value="Login" />
                </form>
              </div>
            </div>
          )}
          <video
            style={{ zIndex: "10", objectFit: "cover" }}
            ref={videoRef}
            width="100%"
            height="100%"
            muted
          >
            <source
              src="https://starllyprojects.s3.ap-south-1.amazonaws.com/energeek/earth.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      )}
    </>
  );
};

export default LoginPage;

// import React, { useRef, useEffect, useState } from "react";
// import earth from "../images/earth.mp4";
// const LoginPage = () => {
//   const videoRef = useRef(null);
//   const popupRef = useRef(null);
//   const [showPopup, setShowPopup] = useState(false);

//   useEffect(() => {
//     const video = videoRef.current;
//     const popup = popupRef.current;

//     const handleVideoEnd = () => {
//       setShowPopup(true);
//     };

//     video.addEventListener("ended", handleVideoEnd);

//     return () => {
//       video.removeEventListener("ended", handleVideoEnd);
//     };
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       const video = videoRef.current;
//       // if (!video.paused && !video.ended) {
//       video.play();
//       // }
//     }, 5000);

//     return () => {
//       clearTimeout(timer);
//     };
//   }, []);

//   const handleVideoClick = () => {
//     const video = videoRef.current;
//     if (!video.ended) {
//       video.play();
//     } else {
//       // video.pause();
//     }
//   };

//   return (
//     <div>
//       {showPopup && (
//         <div
//           ref={popupRef}
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             backgroundColor: "rgba(0, 0, 0, 0.8)",
//             padding: "20px",
//             borderRadius: "5px",
//             color: "white",
//           }}
//         >
//           <h3>Video Ended</h3>
//           <p>Thank you for watching!</p>
//         </div>
//       )}
//       <video
//         ref={videoRef}
//         src={earth}
//         onClick={handleVideoClick}
//         style={{ width: "100%", height: "100vh", objectFit: "cover" }}
//       />
//     </div>
//   );
// };

// export default LoginPage;
