import styles from "./rightSubPanelCont2.module.css";
import energy from "../../.././../images/energy.svg";
import tree from "../../.././../images/tree.svg";
import co2 from "../../.././../images/co2.svg";
import CountUp, { useCountUp } from "react-countup";

function RightPanelSubCont2() {
  const DUMMY_ARR = [1, 2, 3];

  return (
    <div className={styles.rightPanelSubCont2}>
      <div className={styles.sub1}>
        <div className={styles.flexRow}>
          <span>
            {" "}
            <CountUp end={2380} delay={2} />
            kg
          </span>
          <span>CO2 emission saved in the last 4 months</span>
        </div>
        <img src={co2} width="22%" height="80%" />
      </div>

      <div class={styles.sub1}>
        <div className={styles.flexRow}>
          <span>
            {" "}
            <CountUp end={121} delay={2} />
            &#13217;
          </span>
          <span>Forest reserved in 2023</span>
        </div>
        <img src={tree} width="20%" height="80%" />
      </div>

      <div className={styles.sub3}>
        <div className={styles.sub3Sub}>
          <div className={styles.flexRow3} style={{ width: "100%" }}>
            <span>
              {" "}
              <CountUp end={116} delay={2} />
              kWh
            </span>
            <span>Energy saved last 4 months</span>
          </div>
          <img src={energy} width="20%" height="110%" />
        </div>

        {/* <div className={styles.roundCont}>
          <div className={styles.roundSubCont}>
            <div className={styles.round}></div>
            <span>L12</span>
          </div>

          <div className={styles.roundSubCont}>
            <div className={styles.round1}></div>
            <span>L9</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default RightPanelSubCont2;
