import styles from "./overviewCards.module.css";
import StackedChart from "./stackedChart";

function OverviewCards() {
  const stackedAreaChart = [
    {
      Parameter: "Population L12",
      Jan: "10.5",
      Feb: "18.14",
      Mar: "23.58",
      Apr: "19.47",
      May: "16.58",
      Jun: "13.1",
      Jul: "21.59",
      Aug: "17.81",
      Sep: "24.23",
      Oct: "19",
      Nov: "17.45",
      Dec: "20.56",
    },
    {
      Parameter: "Population L9",
      Jan: "30",
      Feb: "32",
      Mar: "29",
      Apr: "37",
      May: "40",
      Jun: "31",
      Jul: "28",
      Aug: "32",
      Sep: "35",
      Oct: "29",
      Nov: "31",
      Dec: "28",
    },
    {
      Parameter: "Rodent Alert",
      Jan: "1.58",
      Feb: "1.1",
      Mar: "0.59",
      Apr: "1.81",
      May: "2.23",
      Jun: "1",
      Jul: "1",
      Aug: "1.14",
      Sep: "1.08",
      Oct: "1.05",
      Nov: "1.1",
      Dec: "0.0",
    },
    {
      Parameter: "Temperature",
      Jan: "24",
      Feb: "25.7",
      Mar: "28.2",
      Apr: "32",
      May: "33.2",
      Jun: "31.76",
      Jul: "30",
      Aug: "32",
      Sep: "29",
      Oct: "30",
      Nov: "31",
      Dec: "28",
    },
  ];

  const points = [
    [
      { x: 0, y: 100 },
      { x: 10, y: 20 },
      { x: 20, y: 50 },
      { x: 30, y: 20 },
      { x: 40, y: 50 },
      { x: 50, y: 20 },
      { x: 60, y: 20 },
      { x: 70, y: 20 },
      { x: 80, y: 30 },
      { x: 90, y: 50 },
      { x: 100, y: 30 },
      { x: 110, y: 50 },
    ],
    [
      { x: 0, y: 20 },
      { x: 10, y: 30 },
      { x: 20, y: 50 },
      { x: 30, y: 20 },
      { x: 40, y: 40 },
      { x: 50, y: 20 },
      { x: 60, y: 90 },
      { x: 70, y: 30 },
      { x: 80, y: 60 },
      { x: 90, y: 50 },
      { x: 100, y: 10 },
      { x: 110, y: 10 },
    ],
    [],
    [
      { x: 0, y: 50 },
      { x: 10, y: 20 },
      { x: 20, y: 50 },
      { x: 30, y: 20 },
      { x: 40, y: 10 },
      { x: 50, y: 40 },
      { x: 60, y: 90 },
      { x: 70, y: 30 },
      { x: 80, y: 40 },
      { x: 90, y: 80 },
      { x: 100, y: 50 },
      { x: 110, y: 20 },
    ],
  ];

  return (
    <section className={styles.overviewCont}>
      {points.map((param, i) => (
        <StackedChart
          stackedAreaChart={stackedAreaChart[i]}
          i={i}
          data={param}
        />
      ))}
      {/* <div className={styles.overviewContDiv}>
        <div className={styles.overviewSubCont}></div>
      </div>
      <div className={styles.overviewContDiv}>
        <div className={styles.overviewSubCont}></div>
      </div>
      <div className={styles.overviewContDiv}>
        <div className={styles.overviewSubCont}></div>
      </div>
      <div className={styles.overviewContDiv}>
        <div className={styles.overviewSubCont}>
          <div className={styles.subContTop}></div>
          <div className={styles.subContWave}></div>
        </div>
      </div> */}
    </section>
  );
}

export default OverviewCards;
