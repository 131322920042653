import styles from "./rightPanel.module.css";
import RightPanelSubCont1 from "./rightPanels/rightSubPanelCont1/rightSubPanelCont1";
import RightPanelSubCont2 from "./rightPanels/rightSubPanelCont2/rightSubPanelCont2";

function RightPanel(props) {
  return (
    <div class={styles.rightPanelCont}>
      <div class={styles.rightPanelContSub1}>
        <RightPanelSubCont1 setDate={props.setDate} />
      </div>
      <div class={styles.rightPanelContSub2}>
        <RightPanelSubCont2 />
      </div>
    </div>
  );
}

export default RightPanel;
