import styles from "./leftPanelSubCont2.module.css";
import sound from "../../../images/sound.svg";
import leaf from "../../../images/leaf.svg";
import humidity from "../../../images/humidity.svg";
import pm from "../../../images/pm.svg";
import formaldehyde from "../../../images/Formaldehyde.svg";
import co2emi from "../../../images/co2emi.svg";
import bulb from "../../../images/bulb.svg";
import CountUp, { useCountUp } from "react-countup";
import { useEffect, useState } from "react";

const Popup = ({ name, text }) => {
  return (
    <div className={styles.popup}>
      <span style={{ color: "black", fontWeight: "700" }}>
        {name}&nbsp;:&nbsp;
      </span>
      {text}
    </div>
  );
};

const HoverableDiv = ({ name, text, children }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <div
      className={styles.sub0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id="sub0"
    >
      {children}
      {showPopup && <Popup name={name} text={text} />}
    </div>
  );
};

function LeftPanelSubCont2(props) {
  const dataArr = [
    {
      name: "Noise Index",
      img: sound,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.sound || 0,
      avg: props.deviceData?.week?.sound,
      unit: "dBA",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.sound < 35
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.sound >= 35 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.sound <= 45
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.sound > 46
          ? "#ff4500"
          : "white",
      popupText:
        "Good office acoustics are a significant contributor to workplace performance and well-being. Acoustic comfort is accomplished when the workplace offers enough acoustical support for interaction, privacy, and concentration. In commercial areas, where offices, shops, and similar establishments are located, acceptable noise levels are typically higher than  residential areas but still need to ensure a productive and comfortable environment. Noise limits can vary, but levels around 55-65 dB during the day and 45-55 dB during the night are common.",
    },
    {
      name: "TVOC",
      img: leaf,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.tvoc || 0,
      avg: props.deviceData?.week?.tvoc,
      unit: "ppb",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]?.tvoc <
        500
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.tvoc >= 500 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.tvoc <= 1000
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.tvoc > 1000
          ? "#ff4500"
          : "white",
      popupText:
        "Studies show that VOC concentrations are 2 to 5 times higher indoors than outdoors, which may lead to both short-term and long-term health problems. Short-term exposure to VOCs indoors can cause headaches, loss of coordination, eye, nose, and throat irritation while long-term exposure may cause liver or kidney damage and even cancer. The acceptable level of VOC indoors is 0-600ppb. You should not expect short-term effects such as irritation or discomfort.",
    },

    {
      name: "Humidity",
      img: humidity,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.humidity || 0,
      avg: props.deviceData?.week?.humidity,
      unit: "%",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.humidity < 30
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.humidity >= 30 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.humidity <= 60
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.humidity > 60
          ? "#ff4500"
          : "white",
      popupText:
        "The optimum humidity range for indoor environments is between 30% to 50%. This level ensures that employees can work comfortably, stay focused and remain healthy. Maintaining a humidity level close to 50% prevents the growth of dust mites, mold, mildew, reduces the risk of bacteria and viruses surviving, and minimizes off-gassing of VOCs.",
    },

    {
      name: "PM 2.5",
      img: pm,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.pm25 || 0,
      avg: props.deviceData?.week?.pm25,
      unit: `µg/㎥`,
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.pm25 >= 0 &&
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.pm25 <= 12
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.pm25 >= 13 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.pm25 <= 35
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.pm25 > 35
          ? "#ff4500"
          : "white",
      popupText:
        "The EPA and many health organizations categorize particulate matter by size because different sizes of particles have different health effects. Small particles less than 2.5 micrometres in diameter are referred to as PM2.5. 0-12µg/m3 is a safe level of PM2.5 concentration in the air for short periods of time. There is little to no danger in these concentrations. EPA recommends an annual standard minimum exposure of 12 µg/m3.",
    },

    {
      name: "Formaldehyde",
      img: formaldehyde,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.ch2o || 0,
      avg: props.deviceData?.week?.ch2o,
      unit: "ppb",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]?.ch2o <
        100
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.ch2o >= 100 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.ch2o <= 300
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.ch2o > 300
          ? "#ff4500"
          : "white",
      popupText:
        "Formaldehyde is a colourless, pungent-smelling gas utilized in the production of construction materials and numerous home goods. Airborne concentrations of formaldehyde above 100 ppb can cause respiratory tract irritations. OSHA recommends an 8-hour exposure limit of 500 ppb. Odor thresholds (the lowest concentrations at which you can detect it) are around 100 ppb.",
    },

    {
      name: "Carbon Dioxide",
      img: co2emi,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]?.co2 ||
        0,
      avg: props.deviceData?.week?.co2,
      unit: "ppm",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]?.co2 <
        800
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.co2 >= 801 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.co2 <= 1000
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.co2 > 1000
          ? "#ff4500"
          : "white",
      popupText:
        "The main source of CO2 inside the workplace are humans. Every time we exhale, we release CO2 into the workplace. High CO2 levels in the workplace may cause headaches and other problems such as fatigue, loss of energy, anxiety and difficulty breathing. It has also been proven to reduce productivity and increase workplace illnesses. The average CO2 concentration indoors in well-ventilated spaces range from 400-800ppm. This amount of CO2 in the air does not pose any problems. Fresh air contains about 400 ppm of CO2, and maintaining levels at 600 ppm or less will keep most spaces fresh.",
    },

    {
      name: "Light",
      img: bulb,
      value:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.light || 0,
      avg: props.deviceData?.week?.light,
      unit: "lux",
      color:
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.light >= 50 &&
        props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
          ?.light <= 99
          ? "yellow"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.light >= 100 &&
            props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.light <= 999
          ? "#17e117d1"
          : props.deviceData?.[`${props.mode === "day" ? "day" : "minute"}`]
              ?.light > 1000
          ? "#ff4500"
          : "white",
      popupText:
        "The normal work light level in offices, laboratories, libraries, and showrooms is 500 lux. At this level, workers are able to perform their daily tasks in a safe and comfortable manner. For tasks requiring a high level of precision and detail like landscape drawing and mechanical production, lighting levels of 750 to 1,000 lux should be used",
    },
  ];

  useEffect(() => {
    let offcIndexSum2 = 0;
    dataArr.map((el) => {
      if (el.color === "#17e117d1") {
        offcIndexSum2++;
      }
    });
    props.fetchOfficeIndex2(offcIndexSum2);
  }, [props.deviceData]);

  return (
    <div class={styles.LeftPanelSubCont2}>
      {dataArr.map((el, i) => {
        return (
          <HoverableDiv name={el.name} text={el.popupText} key={i}>
            <div class={styles.sub1}>
              <img src={el.img} width="45%" height="45%" />
              <span style={{ color: el.color, fontSize: "1.2rem" }}>
                <CountUp
                  style={{ color: el.color, fontSize: "1.2rem" }}
                  end={el.value}
                  delay={2}
                />{" "}
                {el.unit}
              </span>
              <span style={{ whiteSpace: "nowrap", fontSize: "0.9rem" }}>
                Avg{" "}
                <CountUp
                  style={{ fontSize: "0.9rem" }}
                  end={el.avg}
                  delay={2}
                />{" "}
                {el.unit}
              </span>
            </div>
            <span style={{ whiteSpace: "nowrap" }}>{el.name}</span>
          </HoverableDiv>
        );
      })}
    </div>
  );
}

export default LeftPanelSubCont2;
